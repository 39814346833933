import { React, useState } from 'react';
import Data from '../Data.json';

const Experiences = () => {
    const [activeCard, setActiveCard] = useState(1);
    const [userInteracted, setUserInteracted] = useState(false);
  
    const handleMouseHover = (cardNumber) => {
      setActiveCard(cardNumber);
      setUserInteracted(true);
    };
  
    const handleMouseLeave = () => {
      if (!userInteracted) {
        setActiveCard(null);
      }
    };
  
    return (
      <section className="flex flex-col pt-0 lg:pt-5">
  
        <h3 className="mb-5 px-1 lg:px-5 font-bold text-xl">EXPERIENCE</h3>
  
        {Data.experiences.map((experience) => (
          <div 
            key={experience.id}
            className={`grid grid-cols-1 sm:grid-cols-4 mb-5 rounded-xl p-5 card card-${experience.id} ${activeCard === experience.id ? 'active' : ''}`}
            onMouseOver={() => handleMouseHover(experience.id)}
            onMouseLeave={() => handleMouseLeave(experience.id)}
          > 
            <div className="col-span-1 text-sm mt-1 mb-3 sm:mb-0">{experience.date}</div>
            <div className="col-span-1 sm:col-span-3 flex flex-col">
              <p className="font-bold text-xl mb-3 text-black">{experience.jobTitle}{experience.company && ` • ${experience.company}`}</p>
              <p className="text-justify mb-3">{experience.description}</p>
              <div className="flex flex-wrap">
                {experience.skills.map((skill, index) => (
                  <div key={index} className="px-2 py-1 me-1 my-1 rounded text-gray-800 bg-purple-500/10">  
                    {skill}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
  
      </section>
    )
}

export default Experiences;
