import { useEffect } from 'react';
import ReactGA from 'react-ga4';

const Analytics = () => {
  useEffect(() => {
    ReactGA.initialize('G-9X8ZDCQPEW');
    ReactGA.send('pageview');
  }, []);

  return null;
};

export default Analytics;
