import { React, useState } from "react";

export default function Modal(props) {
  const { data } = props;
  const [showModal, setShowModal] = useState(false);
  const handleMoreInfoClick = () => {
    window.location.href = data.view_more_link;
  };

  return (
    <>
      <button
        className="text-xl mb-2 text-black font-bold text-left transition-colors hover:text-blue-500"
        type="button"
        onClick={() => setShowModal(true)}
      >
        {data.title}
      </button>
      {showModal ? (
        <>
          <div className="items-center flex overflow-x-hidden overflow-y-scroll fixed inset-0 z-50">
            <div className="relative w-auto my-6 mx-auto min-w-[90vw] lg:min-w-[40vw] max-w-[90vw] lg:max-w-[40vw]">
              <div className="rounded-lg shadow-lg relative flex flex-col w-full bg-white p-5">
                {/* Content */}
                <div className="flex flex-col justify-between p-5 rounded-t">
                  <h5 className="font-bold text-xs mb-2 text-slate-400">PROJECT</h5>
                  <h3 className="text-3xl font-semibold mb-2">{data.title}</h3>
                  <div className="mt-1 mb-5 flex flex-row flex-wrap">
                    {data.languages.map((language, index) => (
                      <span key={index} className="text-xs border rounded-full px-2 py-1 mr-1 mb-2">
                        {language}
                      </span>
                    ))}
                  </div>
                  {data.long_description}
                </div>
                {/* Footer */}
                <div className="flex items-center justify-end p-6 rounded-b">
                  <button
                    className="text-red-500 font-bold px-6 py-2 text-sm mr-1 mb-1"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                  <button
                    className="bg-black text-white hover:bg-slate-700 font-bold text-sm px-6 py-3 rounded-full mr-1 mb-1"
                    type="button"
                    onClick={handleMoreInfoClick}
                  >
                    More info
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 opacity-25 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
