import React from 'react';
import Data from '../Data.json';

const BasicInfo = () => {
  return (
    <>
      <div className="flex flex-col items-center text-center">
        {/* Profile Picture */}
        <img
          src={Data.basic_info.profile_pic || "/images/profile.jpg"} // Use a fallback if the JSON doesn't contain the pic
          alt={`Profile of ${Data.basic_info.name}`}
          className="h-64 w-63 rounded-lg mt-[-29px] mb-6 border-2 border-gray-300 shadow-lg transition-all duration-300 ease-in-out hover:scale-110"
        />
        {/* Name */}
        <h1 className="text-4xl sm:text-5xl font-bold mt-3 mb-3 text-black">{Data.basic_info.name}</h1>
        {/* Byline */}
        <h2 className="text-lg sm:text-xl mt-2 mb-4 text-black">{Data.basic_info.byline}</h2>
        {/* Bio */}
        <p>{Data.basic_info.bio}</p>
      </div>
    </>
  )
}

export default BasicInfo;
