import React from "react";
import Data from '../Data.json';

const SocialLinks = () => {
  return (
    <ul className="flex flex-row items-center relative"> {/* Corrected class name from 'flew-row' to 'flex-row' and used proper comment style */}
      {Data.socials.map((social) => (
        <li key={social.id} className="mr-3">
          <a href={social.href}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-medium me-2" viewBox="0 0 16 16">
              <path d={social.path_d}/>
            </svg>
          </a>
        </li>
      ))}
      {/* Version number */}
      <li className="mr-3"> {/* Position the version number absolutely */}
        <span className="text-xs leading-5 font-semibold bg-slate-400/10 rounded-lg py-1 px-3">v2.0.8</span>
      </li>
      {/* Download button */}
      <li className="mr-3">
        <a href="/images/Ashwin_updated.pdf" download="Resume_Ashwin_Singaram.pdf"> {/* Assuming '/path/to/Resume.pdf' is the correct path and setting a download name */}
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Download Resume
          </button>
        </a>
      </li>
    </ul>
  );
}

export default SocialLinks;
